import * as React from 'react';
import { Link } from 'gatsby';
import ClassName from '@utils/helpers/ClassName';
import './Text.scss';

export type Colors = 'brand' | 'neutral' | 'blue-dodger' | 'navy' | 'accent' | 'white' | 'blue-prussian' | 'light-blue';

type Elements = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'a' | 'small' | 'div' | 'li';

type FontSize =
  | 'header-1'
  | 'header-2'
  | 'header-3'
  | 'header-4'
  | 'header-5'
  | 'header-6'
  | 'header-7'
  | 'button-menu'
  | 'body-primary'
  | 'body-secondary';

type FontWeight = 'normal' | 'medium' | 'semi-bold' | 'bold';

interface Props extends React.HTMLProps<HTMLParagraphElement> {
  fontSize?: FontSize;
  fontWeight?: FontWeight;
  color?: Colors;
  className?: string;
  as?: Elements;
  lineHeight?: string | number;
  to?: string;
  align?: 'left' | 'center' | 'right';
}

const Text: React.FC<Props> = ({
  children,
  className,
  as = 'span',
  to,
  color,
  fontWeight,
  align = 'left',
  href,
  style,
  fontSize,
  ...rest
}) => {
  const isLink = to || href;
  const defaultColor = isLink ? 'accent' : 'neutral-primary';
  const Component: any = to ? Link : as;
  const fontWeightClassName = fontWeight ? `TDB-Text--fw-${fontWeight}` : '';
  const colorClassName = `TDB-Text--color-${color || defaultColor}`;
  const fontSizeClassName = fontSize ? `TDB-Text--fs-${fontSize}` : '';

  return (
    <Component
      style={{ textAlign: align, ...style }}
      className={ClassName.join('TDB-Text', fontSizeClassName, fontWeightClassName, colorClassName, className)}
      to={to}
      href={href}
      {...rest}
    >
      {children}
    </Component>
  );
};

export default Text;
