import * as React from 'react';
import Button from '@components/Button';
import Container from '@components/Container';
import links from '@constants/links';
import EnterpriseFeatureItem from './EnterpriseFeatureItem';
import './EnterpriseFeatures.scss';
import Embedded from './assets/embedded.png';
import Cloud from './assets/cloud.png';
import OnPrem from './assets/onprem.png';

const EnterpriseFeatures: React.FC = () => {
  return (
    <>
      <Container className="TDB-EnterpriseFeatures">
        <EnterpriseFeatureItem
          title="TileDB Embedded + Support"
          description="For organizations that prefer open-source solutions and an ecosystem of tools with no lock-in."
          features={[
            'Priority escalation of feature requests',
            'Performance optimizations',
            'Domain-specific support to enable use of TileDB Embedded in your products',
          ]}
          image={Embedded}
        />
        <EnterpriseFeatureItem
          title="TileDB Cloud SaaS"
          description="For organizations that want to eliminate infrastructure hassles."
          features={[
            'TileDB Cloud Organizations feature for consolidated usage monitoring and access controls',
            'Guaranteed project requirements',
          ]}
          image={Cloud}
        />
        <EnterpriseFeatureItem
          title="TileDB Cloud On-Prem"
          description="For organizations that prefer full control under their own governance and infrastructure."
          features={[
            'LDAP/AD, Okta, GitHub SSO, Google SSO & local auth',
            'Guaranteed project requirements',
            'Flexible on-premises / in-VPC deployment assistance',
          ]}
          image={OnPrem}
        />
      </Container>
      <div className="TDB-EnterpriseFeatures__button-container">
        <Button className="TDB-EnterpriseFeatures__button" primary to={links.contact}>
          Contact us for pricing
        </Button>
      </div>
    </>
  );
};

export default EnterpriseFeatures;
