import './EnterprisePage.scss';
import React from 'react';
import Footer from '@components/Footer';
import IndexLayout from '@layouts/index';
import Hero from '@components/Hero';
import Text from '@components/Text';
import Header from '@components/Header';
import EnterpriseCapabilities from './EnterpriseCapabilities';
import shareImage from './assets/thumbnail.jpeg';
import heroBackgroundImage from './assets/TileDB_Enterprise_hero.png';
import EnterpriseFeatures from './EnterpriseFeatures';

const EnterprisePage: React.FC = () => {
  return (
    <IndexLayout
      pageName="enterprise"
      helmet={{
        title: 'TileDB Enterprise',
        description:
          'TileDB Enterprise is designed for demanding use cases and large analytical workloads. Get world-class support and a variety of deployment models, including on-prem.',
        shareImage: {
          url: shareImage,
          width: 2000,
          height: 1050,
        },
      }}
    >
      <Header variant="transparent-white" />
      <Hero
        className="TDB-EnterprisePage__hero"
        backgroundImage={heroBackgroundImage}
        description="TileDB Enterprise is designed for your most demanding use cases and business-impacting analytical workloads. It offers access to our domain experts, coupled with world-class product support, and is available in a variety of deployment models outlined below."
        title="Rethink your data infrastructure"
        lightBackground
        gradient="linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)"
        header={
          <>
            TILEDB{' '}
            <Text className="TDB-EnterprisePage__grad-text" color="accent">
              ENTERPRISE
            </Text>
          </>
        }
      />
      <EnterpriseFeatures />
      <EnterpriseCapabilities />
      <Footer variant="light" />
    </IndexLayout>
  );
};

export default EnterprisePage;
