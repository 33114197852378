import * as React from 'react';
import Container from '@components/Container';
import Typography from '@components/Typography';
import domainExpertiseIcon from './assets/domain_expertise.png';
import futureProofIcon from './assets/future-proof_infrastructure.png';
import productAssuranceIcon from './assets/product_assurance.png';
import './EnterpriseCapabilities.scss';

const EnterpriseCapabilities: React.FC = () => (
  <div className="TDB-EnterpriseCapabilities">
    <div className="TDB-EnterpriseCapabilities__gradient" />
    <Typography as="h2" className="TDB-EnterpriseCapabilities__title" align="center" fontSize="heading-1" fontWeight="bold">
      Why TileDB Enterprise
    </Typography>
    <Container>
      <div className="TDB-EnterpriseCapabilities__main">
        <div className="TDB-EnterpriseCapabilities__item">
          <img className="TDB-EnterpriseCapabilities__icon" alt="domain expertise" src={domainExpertiseIcon} />
          <Typography
            className="TDB-EnterpriseCapabilities__item-title"
            as="h5"
            fontSize="subheading-1"
            fontWeight="semi-bold"
            color="neutral-800"
          >
            Domain expertise
          </Typography>
          <Typography as="p" fontSize="body-lg" fontWeight="medium" color="neutral-600">
            Specialized domains require specialized skills. The TileDB team is staffed with experts across a range of industry domains who
            provide detailed advice and domain-specific support. For example: In genomics, we enable rapid genome analysis of NICU patients,
            saving babies’ lives. In telecoms and geospatial engineering, we enable analysis of digital twins and accelerate large-scale
            spatial statistics. What will TileDB do for your industry?
          </Typography>
        </div>

        <div className="TDB-EnterpriseCapabilities__item">
          <img className="TDB-EnterpriseCapabilities__icon" alt="future proof" src={futureProofIcon} />
          <Typography
            className="TDB-EnterpriseCapabilities__item-title"
            as="h5"
            fontSize="subheading-1"
            fontWeight="semi-bold"
            color="neutral-800"
          >
            Future-proof infrastructure
          </Typography>
          <Typography as="p" fontSize="body-lg" fontWeight="medium" color="neutral-600">
            Shift data engineering to an experienced partner. TileDB provides consultative support to create an architecture that evolves
            with your use cases and analytics workloads. From customized data ingestion to schema optimization work, our engineers get
            hands-on in your environment, so your team can focus on what they do best: analysis and insight.
          </Typography>
        </div>

        <div className="TDB-EnterpriseCapabilities__item">
          <img className="TDB-EnterpriseCapabilities__icon" alt="product assurance" src={productAssuranceIcon} />
          <Typography
            className="TDB-EnterpriseCapabilities__item-title"
            as="h5"
            fontSize="subheading-1"
            fontWeight="semi-bold"
            color="neutral-800"
          >
            Product assurance
          </Typography>
          <Typography as="p" fontSize="body-lg" fontWeight="medium" color="neutral-600">
            Get peace of mind from a commercial relationship. Your TileDB contract includes dedicated support channels, prioritized
            escalations, and custom training sessions to accelerate time-to-insight and enhance your team’s productivity.
          </Typography>
        </div>
      </div>
    </Container>
  </div>
);

export default EnterpriseCapabilities;
