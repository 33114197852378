import * as React from 'react';
import classNames from 'classnames';
import Container from '../Container';
import Typography from '../Typography';
import './Hero.scss';

type Props = {
  header: React.ReactNode;
  title: React.ReactNode;
  description: string;
  lightBackground?: boolean;
  actions?: React.ReactNode;
  align?: 'center' | 'left' | 'right';
  backgroundImage?: string;
  gradient?: string;
  className?: string;
};

const Hero: React.FC<Props> = (props) => {
  const { title, header, description, actions, align = 'center', backgroundImage, gradient, lightBackground, className } = props;

  return (
    <header className={classNames('TDB-Hero', className)} style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="TDB-Hero__gradient" style={{ background: gradient }} />
      <Container className="TDB-Hero__container">
        <Typography
          fontWeight="bold"
          className="TDB-Hero__header"
          align={align}
          color={lightBackground ? 'neutral-800' : 'neutral-main'}
          as="h3"
        >
          {header}
        </Typography>
        <Typography
          className="TDB-Hero__title"
          align={align}
          as="h1"
          fontSize="display-1"
          color={lightBackground ? 'neutral-800' : 'neutral-main'}
        >
          {title}
        </Typography>
        <Typography
          className="TDB-Hero__description"
          as="h5"
          fontSize="heading-5"
          align={align}
          fontWeight="medium"
          color={lightBackground ? 'neutral-600' : 'brand-100'}
        >
          {description}
        </Typography>
        {actions && <div className={classNames('TDB-Hero__actions', { 'TDB-Hero__actions--left': align === 'left' })}>{actions}</div>}
      </Container>
    </header>
  );
};

export default Hero;
