import * as React from 'react';
import Typography from '@components/Typography';
import './EnterpriseFeatureItem.scss';

type Props = {
  title: string;
  description: string;
  features: string[];
  image: string;
};

const EnterpriseFeatureItem: React.FC<Props> = (props) => {
  const { title, description, features = [], image } = props;
  return (
    <div className="TDB-EnterpriseFeatureItem">
      <div className="TDB-EnterpriseFeatureItem__main">
        <Typography fontSize="heading-1" fontWeight="bold" as="h3" className="mb-6">
          {title}
        </Typography>
        <Typography fontSize="subheading-2" fontWeight="semi-bold" as="p" color="neutral-600">
          {description}
        </Typography>
        <Typography as="p" fontWeight="bold" fontSize="overline" className="TDB-EnterpriseFeatureItem__overline">
          WHAT'S INCLUDED
        </Typography>
        {features.map((f) => (
          <Typography className="TDB-EnterpriseFeatureItem__item" key={f} fontSize="body-lg" fontWeight="medium" as="p" color="neutral-600">
            {f}
          </Typography>
        ))}
      </div>
      <img className="TDB-EnterpriseFeatureItem__image" src={image} alt="lock icon" />
    </div>
  );
};

export default EnterpriseFeatureItem;
